"use client";

import { SessionProvider as NextAuthSessionProvider, SessionProviderProps } from "next-auth/react";
import { PropsWithChildren } from "react";
import { Provider as JotaiProvider } from "jotai";

export function SessionProvider(props: PropsWithChildren<SessionProviderProps>) {
  return (
    <JotaiProvider>
      <NextAuthSessionProvider {...props} />
    </JotaiProvider>
  );
}
